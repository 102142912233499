import React, { useRef, useEffect } from "react";
import "./LandingPage.css";
import Slider from "../../Components/Slider/Slider";
import emergency from "../../Assets/Images/emergency.jpg";
import appointment from "../../Assets/Images/appontment.jpg";
import Button from "../../Components/Button/Button";
import AboutUs from "./AboutUs";
import whyUs from "../../Assets/Images/whyChoose.png";
import Practices from "./Practices";
import Disclaimer from "../../Components/Disclamer/Disclaimer";
import ContactUs from "../../Components/ContactUs/ContactUs";
import hero2 from "../../Assets/Images/abtUs.jpg";
import WhatsAppWidget from "../../Components/Widget/WhatsAppWidget";
import AOS from 'aos';
import 'aos/dist/aos.css';

const LandingPage = (props) => {

  const { refState, contact, executeScrollContact, ourPractice } = props;

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <Slider />

      <WhatsAppWidget />

      <div data-aos="fade-up" className="emergency-contact-landingPg">

        {/* <div className="poster-container-emergency">
          <div className="poster posterTwo">
            <img src={appointment} alt="" srcset="" />
          </div>
          <div className="poster posterOne">
            <img src={emergency} alt="" srcset="" />
          </div>
        </div> */}

        <div  className="emergency-contact-container ">

          <div className="emergency-contact-container-two emg-container">
            <div className="txt-container-emg txt-container-emg-two">
              <p className="head-emergency-text">Require legal assistance?</p>
              <p className="desc-emergency-text">
                Fix an Appointment for Advice / Guidance / Assistance <br /> on
                any Legal Matter :
              </p>
              <Button text="Fix An Appointment" clickEvent={executeScrollContact} />
            </div>
          </div>

          <div className="emergency-contact-container-one emg-container">
            <div className="txt-container-emg">
              <p className="head-emergency-text">Emergency Cases ?</p>
              <p className="desc-emergency-text">
                Contact for Quick Legal Assistance :
              </p>
              <p className="txt">Call Us Now</p>
              <p className="txt-bold">+91 7773 992 917</p>
            </div>
          </div>

        </div>

      </div>

      <AboutUs  refState={refState} />

      <Disclaimer />

      <div data-aos="fade-up" className="aboutus-container">
        <div className="aboutus-container-parent maxWidth ">
          <div className="abtus-content abtus-content-landing">
            <div className="abtus-content-img">
              <div className="abtus-content-img-div abtus-content-img-div-landing">
                <img src={whyUs} alt="" srcset="" />
              </div>
            </div>
            <div className="abtus-content-summary ">
              <p className="abtus-content-summary-title">Why Choose us</p>
              <p className="abtus-content-txt">
                Our team comprises seasoned legal professionals with extensive
                experience across various practice areas. We have the knowledge
                and skills to effectively handle your case and achieve favorable
                outcomes.
              </p>
              <p className="abtus-content-txt">
                At BookMyLegalService, you are our top priority. We take the
                time to understand your unique needs and objectives, and we
                tailor our services accordingly. Your satisfaction and success
                are paramount to us.
              </p>
              <p className="abtus-content-txt">
                We are committed to delivering results. Whether it's negotiating
                a settlement, representing you in court, or providing strategic
                legal advice, we will work tirelessly to achieve the best
                possible outcome for you.
              </p>
              <p className="abtus-content-txt">
                Choose BookMyLegalService for reliable, client-centered legal
                representation. Let us put our experience and skills to work for
                you. Contact us today to schedule a consultation and take the
                first step towards resolving your legal matters with confidence.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div data-aos="fade-up" className="aboutus-container">
        <div className="aboutus-container-parent maxWidth ">
          <div className="abtus-content abtus-content-reverse">
            <div className="abtus-content-summary">
              <p className="abtus-content-summary-title">SpecialLization</p>
              <p className="abtus-content-txt">
                Comprehensive Legal Representation: <br /> Specializing in Civil,
                Criminal, and Family Law Cases
              </p>
              <p className="abtus-content-txt">
                At BookMyLegalService, we offer comprehensive legal
                representation, focusing on Civil, Criminal, and Family Law
                cases, among others. <br /><br /> With our expertise in navigating these
                intricate legal landscapes, we provide tailored solutions to
                meet your specific needs and ensure the best possible outcomes
                for your case. <br /><br /> Whether you're facing a civil dispute, criminal
                charges, or family matters, you can trust us to advocate for
                your rights and guide you through every step of the legal
                process with professionalism and dedication.
              </p>
            </div>
            <div className="abtus-content-img abtus-content-img-end">
              <div className="abtus-content-img-div">
                <img src={hero2} alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Practices ourPractice={ourPractice} />

      <ContactUs refState={contact} />
    </>
  );
};

export default LandingPage;
