import React, { useState, useEffect, useRef } from 'react';
import practiceHead from "../../Assets/Images/bg1.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import saleAgreement from "../../Assets/Images/saleAgreement.svg"
import partnership from "../../Assets/Images/partnerShip.svg"
import notice from "../../Assets/Images/notice.svg"
import lease from "../../Assets/Images/lease.svg"
import will from "../../Assets/Images/willMaking.svg"
import legalDraft from "../../Assets/Images/legalDraft.svg"
import adoption from "../../Assets/Images/adoption.svg"
import affidavit from "../../Assets/Images/affidavit.svg"
import itr from "../../Assets/Images/tax.svg"
import gst from "../../Assets/Images/gst.svg"
import accounting from "../../Assets/Images/accounting.svg"
import comIncorporation from "../../Assets/Images/company incorporation.svg"
import audit from "../../Assets/Images/audit.svg"
import complance from "../../Assets/Images/complance.svg"
import companyPan from "../../Assets/Images/Company.svg"
import indPan from "../../Assets/Images/indPan.svg"
import homeLoan from "../../Assets/Images/homeLoan.svg"
import mortage from "../../Assets/Images/mortage.svg"
import shopLicence from "../../Assets/Images/shopLicence.svg"
import fssai from "../../Assets/Images/fssai.jpg"
import rera from "../../Assets/Images/rera.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import factory from "../../Assets/Images/factory.svg"

const Practices = (props) => {

  const { ourPractice } = props;

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <div data-aos="fade-up" className="ourPractice-header" ref={ourPractice} >
        <h1>OUR AREAS OF PRACTISE</h1>
        <img src={practiceHead} alt="" srcset="" />
      </div>

      {/* Business Licenses */}

      <div data-aos="fade-up" className="practices-slider">
        <h1 className="practices-slider-header maxWidth abtus-content-summary-title" >Business Licenses</h1>
        <Swiper
          // spaceBetween={30}
          effect={'fade'}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="mySwiper"
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          style={{
            "--swiper-pagination-color": "#cf9455",
            "--swiper-pagination-bullet-inactive-color": "#00000036",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
            "--swiper-pagination-bullet-horizontal-gap": "6px"
          }}
        >
          <SwiperSlide>
            <div className="slide-container-practices slide-container-one-practices ">
              <div className="slide-container-practices-child-container maxWidth">
                <div className="slide-container-practices-child">
                  <img src={shopLicence} alt="" srcset="" />
                  <p>Shop Licenses</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={fssai} className="fssai-logo" alt="" srcset="" />
                  <p>FSSAI Licenses</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={factory} alt="" srcset="" />
                  <p>Factory Licenses</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={rera} className="rera-logo" alt="" srcset="" />
                  <p>RERA Registration</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* legal working */}

      <div data-aos="fade-up" className="practices-slider">
        <h1 className="practices-slider-header maxWidth abtus-content-summary-title" >Legal Working</h1>
        <Swiper
          // spaceBetween={30}
          effect={'fade'}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="mySwiper"
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          style={{
            "--swiper-pagination-color": "#cf9455",
            "--swiper-pagination-bullet-inactive-color": "#00000036",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
            "--swiper-pagination-bullet-horizontal-gap": "6px"
          }}
        >
          <SwiperSlide>
            <div className="slide-container-practices slide-container-one-practices ">
              <div className="slide-container-practices-child-container maxWidth">
                <div className="slide-container-practices-child">
                  <img src={saleAgreement} alt="" srcset="" />
                  <p>Sale Agreement</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={partnership} alt="" srcset="" />
                  <p>Partnership Deed making</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={notice} alt="" srcset="" />
                  <p>Notice  Making</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={lease} alt="" srcset="" />
                  <p>Lease Agreement</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container-practices slide-container-one-practices ">
              <div className="slide-container-practices-child-container maxWidth">
                <div className="slide-container-practices-child">
                  <img src={will} alt="" srcset="" />
                  <p>Will Making</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={legalDraft} alt="" srcset="" />
                  <p>Legal Drafting</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={adoption} alt="" srcset="" />
                  <p>Adoption Of Son Deed</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={affidavit} alt="" srcset="" />
                  <p>Affidavit to Subregistrar</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Taxation Working */}

      <div data-aos="fade-up" className="practices-slider">
        <h1 className="practices-slider-header maxWidth abtus-content-summary-title" >Taxation Working</h1>
        <Swiper
          // spaceBetween={30}
          effect={'fade'}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="mySwiper"
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          style={{
            "--swiper-pagination-color": "#cf9455",
            "--swiper-pagination-bullet-inactive-color": "#00000036",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
            "--swiper-pagination-bullet-horizontal-gap": "6px"
          }}
        >
          <SwiperSlide>
            <div className="slide-container-practices slide-container-one-practices ">
              <div className="slide-container-practices-child-container maxWidth">
                <div className="slide-container-practices-child">
                  <img src={itr} alt="" srcset="" />
                  <p>Income Tax Return Filling</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={gst} alt="" srcset="" />
                  <p>Gst Return Filling</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={accounting} alt="" srcset="" />
                  <p>Accounting</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={comIncorporation} alt="" srcset="" />
                  <p>Company Incorporation</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container-practices slide-container-one-practices ">
              <div className="slide-container-practices-child-container maxWidth">
                <div className="slide-container-practices-child">
                  <img src={audit} alt="" srcset="" />
                  <p>Audit</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={complance} alt="" srcset="" />
                  <p>Compliance</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={companyPan} alt="" srcset="" />
                  <p>Company Pan Card</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={indPan} alt="" srcset="" />
                  <p>Individual Pan Card</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Finance & Loan Facility */}

      <div data-aos="fade-up" className="practices-slider">
        <h1 className="practices-slider-header maxWidth abtus-content-summary-title" >Finance & Loan Facility</h1>
        <Swiper
          // spaceBetween={30}
          effect={'fade'}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="mySwiper"
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          style={{
            "--swiper-pagination-color": "#cf9455",
            "--swiper-pagination-bullet-inactive-color": "#00000036",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
            "--swiper-pagination-bullet-horizontal-gap": "6px"
          }}
        >
          <SwiperSlide>
            <div className="slide-container-practices slide-container-one-practices ">
              <div className="slide-container-practices-child-container maxWidth">
                <div className="slide-container-practices-child">
                  <img src={homeLoan} alt="" srcset="" />
                  <p>Housing Loan</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={companyPan} alt="" srcset="" />
                  <p>Business Loan</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={mortage} alt="" srcset="" />
                  <p>Mortage Loan</p>
                </div>
                <div className="slide-container-practices-child">
                  <img src={indPan} alt="" srcset="" />
                  <p>Individual Loan</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

    </>
  );
};

export default Practices;
