import React from 'react'
import whatsapp from "../../Assets/Images/WhatsApp_icon.png"

const WhatsAppWidget = () => {

    const handleWhatsAppClick = () => {
        const phoneNumber = '7773992917';
        const ownerInfo = 'You are connecting to BookMyLegalService owner Adv. Prem Prakash tiwari';
    
        const message = encodeURIComponent(ownerInfo);
    
        window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
      };

    return (
        <div className='widget-container'>
            <div className="whatsapp-widget" onClick={handleWhatsAppClick}>
                <img src={whatsapp} alt="WhatsApp" />
                <p>Connect On WhatsApp</p>
            </div>
        </div>
    )
}

export default WhatsAppWidget
