import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "./ContactUs.css"
import hero from "../../Assets/Images/contact us.svg"
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';

const ContactUs = (props) => {

    const { refState } = props;

    useEffect(() => {
        AOS.init();
    }, [])

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        number: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // email.js

    const sendEmail = (e) => {
        e.preventDefault();

        // emailjs credentials
        const serviceId = "service_pf205rv";
        const templateId = "template_cnauwnl";
        const publicKey = "PuKjdZLkGzs1riAyQ";

        // dynamic params
        const templateParams = {
            from_name: formData.name,
            to_name: "Adv. Prem Praksh Tiwari",
            mobileNumber: formData.number,
            message: formData.message,
            from_email: formData.email,
        }

        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log('Email Sent', response);
                setFormData({
                    ...formData,
                    name: "",
                    number: "",
                    message: "",
                    name: "",
                    email: "",
                })
            }).catch((err) => {
                console.log(err, "fataa....!");
            })

    };

    // NODE.JS API

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://localhost:5000/send-email', formData);
            alert('Email sent successfully!');
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            console.error('Error sending email:', error);
            alert('An error occurred while sending the email. Please try again later.');
        }
    };


    return (
        <div className='contactUs-container' ref={refState}  >
            <div className='contactUs-container-parent maxWidth' >

                <div data-aos="fade-up" className="contactUs-container-hero-img">
                    <h1>Any query? Let’s connect</h1>
                    <img src={hero} alt="" srcset="" />
                </div>


                <form data-aos="fade-up" onSubmit={sendEmail} className='form-container-parent' >
                    <div className="form-container">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className='input-field'
                            placeholder='Enter Your Name'
                            required
                        />
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className='input-field'
                            placeholder='Enter Your Email ID'
                            required
                        />
                        <input
                            type="number"
                            id="number"
                            name="number"
                            value={formData.number}
                            onChange={handleChange}
                            className='input-field'
                            placeholder='Enter Your Mobile Number'
                            required
                        />
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder='Brief Description of your legal issue'
                            className='input-field input-field-textarea'
                            required
                        />
                        {/* <div className="agreeBtn-container"> */}
                        <button type="submit" className='btn'>Send Message</button>
                        {/* </div> */}
                    </div>

                </form>

            </div>
        </div>
    )
}

export default ContactUs
