import React, {useEffect} from "react";
import Typewriter from "typewriter-effect";
import hero from "../../Assets/Images/tiwariJi.jpeg";
import AOS from 'aos';
import 'aos/dist/aos.css';


const AboutUs = (props) => {

  const { refState } = props;

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="aboutus-container" ref={refState}>
      <div className="aboutus-container-parent maxWidth ">
        <h1 data-aos="fade-up" className="Title-animated">
          <Typewriter
            options={{
              strings: [
                "Where Legal Excellence Meets Convenience,",
                "M/s.Prem Tiwari & Associates,",
                'Introducing "BookMyLegalService"',
              ],
              autoStart: true,
              loop: true,
              delay: 100,
            }}
          />
        </h1>

        <div data-aos="fade-up" className="abtus-content abtus-content-reverse">
          <div className="abtus-content-summary">
            <p className="abtus-content-summary-title">About Us</p>
            <p className="abtus-content-txt">
              Welcome to BookMyLegalService,
            </p>
            <p className="abtus-content-txt">
              Your trusted source for reliable legal assistance, brought to you by <br /> <span>M/s.Prem Tiwari & Associates,</span> the owner of BookMyLegalService. We simplify complex matters with transparency, expertise, and personalized service. Trust us for all your legal needs.
            </p>
            <p className="abtus-content-txt">
              <span>Experience:</span>  Years of proven success and deep legal knowledge. <br />
              <span>Client Testimonials:</span> Hear from satisfied clients who've
              experienced our dedication firsthand.
              <br /> <span> Clear Communication:</span> We keep
              you informed and engaged throughout the process.
              <br /><span> Ethical Standards:</span> Upholding honesty, integrity, and professionalism at
              all times.
              <br /><span>Personalized Approach:</span> Tailored solutions to meet your
              unique needs.
            </p>
          </div>
          <div className="abtus-content-img abtus-content-img-end">
            <div className="abtus-content-img-div">
              <img src={hero} alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
