import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../Assets/Images/court2.png";
import ham from "../../Assets/Images/ham.png";
import cross from "../../Assets/Images/cross.png";

const Navbar = (props) => {
    const {
        refState,
        executeScrollAboutUs,
        executeScrollContact,
        executeScrollPractice,
    } = props;

    const [scrolled, setScrolled] = useState(false);
    const [showMobNav, setshowMobNav] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            const threshold = 60;
            if (scrollTop > threshold) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    const handleMobNav = () => {
        setshowMobNav(!showMobNav);
    }

    return (
        <nav className={`nav_container ${scrolled ? "scrolled" : ""}`}>
            <div className="nav_container_parent  maxWidth">
                <div className="logo-container">
                    <img src={logo} alt="" srcset="" />
                    <p className="companyName">BookMyLegalService</p>
                </div>

                <img src={!showMobNav ? ham : cross} className="ham-icon" alt="" srcset="" onClick={handleMobNav} />

                <div className="nav_menu_container">
                    <p onClick={executeScrollAboutUs}>About us</p>
                    <p onClick={executeScrollPractice}>Our Practice</p>
                    <p onClick={executeScrollContact}>contact us</p>
                    <p onClick={executeScrollContact}>Fix An Appointment</p>
                </div>
            </div>

            {
                showMobNav && (
                    <div className="mob-nav-container">

                        <div className="nav_menu_container-mob">
                            <p onClick={(e) => { executeScrollAboutUs(); handleMobNav(); }} >About us</p>
                            <p onClick={(e) => { executeScrollPractice(); handleMobNav(); }}>Our Practice</p>
                            <p onClick={(e) => { executeScrollContact(); handleMobNav(); }} >contact us</p>
                            <p onClick={(e) => { executeScrollContact(); handleMobNav(); }} >Fix An Appointment</p>
                        </div>

                    </div>
                )
            }
        </nav>
    );
};

export default Navbar;
