import React from 'react'
import './Button.css'

const Button = (props) => {
    const { text, clickEvent, } = props
    return (
        <>
            <input type="button" value={text} className='btn' onClick={clickEvent} />
        </>
    )
}

export default Button
