import React from "react";
import "./Footer.css";
// import logo from "../../Assets/Images/court.png"
import logo from "../../Assets/Images/court2.png";
import linkedin from "../../Assets/Images/linkedin.png";
import fb from "../../Assets/Images/fb.png";
import insta from "../../Assets/Images/insta.png";

const Footer = () => {
    return (
        <footer className="footer_container">
            <div className="footer_container_parent maxWidth">
                <div className="logo-container-social">
                    <div className="logo-container">
                        <img src={logo} alt="" srcset="" />
                        <p className="companyName">BookMyLegalService</p>
                    </div>

                    <div className="social-icons-container">
                        <img src={fb} alt="" srcset="" />
                        <img src={linkedin} className="linkedin" alt="" srcset="" />
                        <img src={insta} alt="" srcset="" />
                    </div>
                </div>

                <p className="footer-address">
                    Sativali Naka, Opp .Hanuman Mandir, Vasai (east), Pincode: 401208, Maharashtra.
                </p>

                <p className="footer-desc">
                    As per the rules of the Bar Council of India, we are not permitted to
                    solicit work and advertise. The user acknowledges that there has been
                    no advertisement and personal communication from BookMyLegalService,
                    any information obtained, or material downloaded from this website is
                    completely at the user’s volition and any transmission, receipt or use
                    of this site would not create any lawyer-client relationship. In cases
                    where the user has any legal issues, he/she in all cases must seek
                    independent legal advice.{" "}
                </p>

                <div className="seprator"></div>



                <div className="copyright-container">
                    <p>2024 BookMyLegalService. All Rights Reserved.</p>
                    <p > M/s.Prem Tiwari & Associates</p>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
