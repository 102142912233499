import React from 'react'
import "./Slider.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import hero1 from "../../Assets/Images/hero11.jpeg"
import hero2 from "../../Assets/Images/hero2.jpg"
import hero3 from "../../Assets/Images/hero3.jpg"
import hero4 from "../../Assets/Images/hero4.jpg"
import hero5 from "../../Assets/Images/hero5.jpg"
import hero6 from "../../Assets/Images/hero6.jpg"

const Slider = () => {
    return (
        <>
            <Swiper
                // spaceBetween={30}
                effect={'fade'}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectFade, Navigation, Pagination, Autoplay]}
                className="mySwiper"
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                style={{
                    "--swiper-pagination-color": "#fff",
                    "--swiper-pagination-bullet-inactive-color": "#00000036",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "16px",
                    "--swiper-pagination-bullet-horizontal-gap": "6px"
                }}
            >
                <SwiperSlide>
                    <div className="slide-container slide-container-one ">
                        <img src={hero1} alt="" srcSet="" />
                        <h1>Your Trusted Legal Partner, <br /> BookMyLegalService Law Firm. </h1>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-container slide-container-two">
                        <img src={hero3} alt="" srcSet="" />
                        <h1>Providing you with much more than just legal expertise.</h1>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-container slide-container-three">
                        <img src={hero4} alt="" srcSet="" />
                        <h1>"Mastering the Art of Law."</h1>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-container slide-container-four">
                        <img src={hero2} alt="" srcSet="" />
                        <h1>Legal Expertise, Personalized Approach.</h1>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-container slide-container-five">
                        <img src={hero5} alt="" srcSet="" />
                        <h1>Our law firm offers quicker, smarter, digitalized, and better services <br /> for our clients.</h1>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-container slide-container-six">
                        <img src={hero6} alt="" srcSet="" />
                        <h1>Championing Your Rights, Guiding Your Journey.</h1>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default Slider
