import React, { useRef } from "react";
import "./App.css";
import { HashRouter, Routes, Route, Link } from "react-router-dom";
import Navbar from "./Layouts/Navbar/Navbar";
import Footer from "./Layouts/Footer/Footer";
import LandingPage from "./Views/LandingPage/LandingPage";

function Root() {
  const aboutUs = useRef(null);
  const contact = useRef(null);
  const ourPractice = useRef(null);

  const executeScrollAboutUs = () =>
    aboutUs.current.scrollIntoView({
      behavior: "smooth",
    });

  const executeScrollContact = () =>
    contact.current.scrollIntoView({
      behavior: "smooth",
    });  
    
    const executeScrollPractice = () =>
    ourPractice.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <HashRouter>
        <Navbar
          executeScrollAboutUs={executeScrollAboutUs}
          executeScrollContact={executeScrollContact}
          executeScrollPractice={executeScrollPractice}
          refState={aboutUs}
        />
        <Routes>
          <Route
            path="/"
            element={
              <LandingPage
                refState={aboutUs}
                executeScrollContact={executeScrollContact}
                contact={contact}
                ourPractice={ourPractice}
              />
            }
          />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default Root;
