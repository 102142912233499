import React, { useState, useEffect } from "react";
import "./Disclaimer.css";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import Button from "../Button/Button";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

Modal.setAppElement("#root");

const Disclaimer = () => {
    const [modalIsOpen, setIsOpen] = useState(true);
    const [isAgreed, setIsAgreed] = useState(false);

    useEffect(() => {
        // Check if the user has previously agreed
        const agreed = localStorage.getItem("agreed");
        if (agreed) {
            setIsAgreed(true);
            closeModal(); // Close modal if user has agreed previously
        }
    }, []); // Empty array ensures this effect runs only once on component mount

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setIsAgreed(true);
        localStorage.setItem("agreed", "true"); // Store the user's agreement status
    }

    // If user has agreed, return null to render nothing
    if (isAgreed) {
        return null;
    }

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="disclaimer-container">
                    <h2>Disclaimer</h2>

                    <p>
                        In compliance with the rules of the Bar Council of India, this
                        website of <span>BookMyLegalService</span>
                        <br />
                        ( the “Firm” ) is meant solely for
                        information about the Firm, its practice areas, advocates and
                        solicitors and not for the purpose of advertising, soliciting work
                        or inducement of any sort by the Firm or any of its members. Nor is
                        it to be construed as legal advice of any nature or manner
                        whatsoever.
                        <br />
                        <br />
                        The content of this website is also the Intellectual
                        Property of the Firm.
                        <br />
                        <br />
                        Please read and accept our website’s Terms of
                        Use and our Privacy Policy. By clicking on ‘I AGREE’ below, the
                        website visitor acknowledges that the information provided in is
                        meant only for his/her understanding of the Firm and its activities.
                        <br />
                    </p>

                    <div className="agreeBtn-container">
                        <Button text="I Agree" clickEvent={closeModal} />
                    </div>

                    {/* <button onClick={closeModal}>close</button> */}
                </div>
            </Modal>
        </div>
    );
};

export default Disclaimer;
